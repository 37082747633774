<template>
    <template v-if="sku.is_draft">
        <div class="alert alert-warning py-1">
            <div class="d-flex justify-content-between align-items-center">
                <span>出品保留中です。</span>
                <template v-if="$store.getters['user/auth/canReleaseSku']()">
                <button class="btn btn-sm btn-warning" @click="release">出品保留を解除</button>
                </template>
            </div>
        </div>
    </template>
    <template v-else-if="SyncStatus.isError(sku.sync_status)">
        <div class="alert alert-danger py-1">
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ sku.sync_status_display }}</span>
                <template v-if="$store.getters['user/auth/canRetryError']()">
                <button class="btn btn-sm btn-danger" @click="retry">再試行</button>
                </template>
            </div>
        </div>
    </template>
    <template v-else-if="SyncStatus.DONE !== sku.sync_status">
        <div class="alert alert-success py-1">
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ sku.sync_status_display }}</span>
            </div>
        </div>
    </template>

    <div class="row">
        <div class="col-md-4">
            <img v-if="sku.asin_cache && sku.asin_cache.img" :src="sku.asin_cache.img" class="w-100" alt="">
            <div v-else class="bg-light p-5">NO IMAGE</div>
        </div>
        <div class="col-md-20">
            <h5>{{ sku.title }}</h5>
            <div class="mb-3 p-1 bg-primary-light">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th>ASIN</th>
                            <th>SKU</th>
                            <th>コンディション</th>
                            <th>リードタイム</th>
                            <th>支払方法</th>
                            <th>販売価格</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ sku.asin }}</td>
                            <td>{{ sku.sku_code }}</td>
                            <td>{{ sku.sub_condition_display }}</td>
                            <td>{{ sku.leadtime }} 日</td>
                            <td>{{ sku.payment_type_exclusion_display }}</td>
                            <td class="text-right">{{ $helper.number(sku.price) }} 円</td>
                        </tr>
                        <tr>
                            <td colspan="6">{{ sku.condition_note }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <template v-if="on_page_active">
            <inline-loader v-if="active_auctions_loading" message="オークション情報を読込中"></inline-loader>
            <table class="table" v-else>
                <colgroup>
                    <col width="10%">
                    <col width="40%">
                    <col width="15%">
                    <col width="15%">
                    <col width="15%">
                    <col width="5%">
                </colgroup>
                <thead>
                    <tr>
                        <th colspan="2">監視中のオークション</th>
                        <th>現在価格</th>
                        <th>即決価格</th>
                        <th>残り時間</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="auction,index of active_auctions" :key="index">
                        <td>
                            <a :href="`${auction.url}?setChromeAsin=${sku.asin}`" target="_blank">
                                <img :src="auction.image" alt="" style="width:50px;">
                            </a>
                        </td>
                        <td>
                            <a :href="`${auction.url}?setChromeAsin=${sku.asin}`" target="_blank">
                                {{ auction.title }}
                            </a>
                        </td>
                        <td>{{ auction.current }}</td>
                        <td>{{ auction.buynow }}</td>
                        <td>{{ auction.period }}</td>
                        <td>
                            <button class="btn btn-sm btn-outline-danger" @click="removeAuction(auction)" v-if="!sku.uneditable">
                                <i class="bi bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </template>
        </div>

        <div class="accordion mb-3" v-if="!sku.uneditable">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button d-block p-2 text-center" :class="{collapsed: !editor_show}" type="button" @click="openEditor">
                        <i class="bi bi-pencil me-2"></i> 出品情報を編集
                    </button>
                </h2>
                <div class="accordion-collapse" :class="{collapse: !editor_show}">
                    <div class="accordion-body">
                        <inline-loader v-if="asin_fee_loading" message="Amazon手数料を取得中"></inline-loader>
                        <template v-else>
                            <table class="table layout-fixed mb-5">
                                <thead>
                                    <tr>
                                        <th>販売価格</th>
                                        <th>予想落札価格</th>
                                        <th>Amazon手数料</th>
                                        <th>送料</th>
                                        <th>その他手数料</th>
                                        <th>利益額</th>
                                        <th>利益率</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ $helper.number(calculator.listing_price) }}</td>
                                        <td><input type="number" class="form-control" v-model="calculator.estimated_cost" @change="calculate"></td>
                                        <td>{{ $helper.number(calculator.referral_fee) }}</td>
                                        <td><input type="number" class="form-control" v-model="calculator.estimated_shipping" @change="calculate"></td>
                                        <td>{{ $helper.number(calculator.other_fee) }}</td>
                                        <td><input type="number" class="form-control" v-model="calculator.profit_price" @change="calculate('profit_price')"></td>
                                        <td><input type="number" class="form-control" v-model="calculator.profit_ratio" @change="calculate('profit_ratio')" step="0.1"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table layout-fixed">
                                <tbody>
                                    <tr>
                                        <td>
                                            <form-select
                                                v-model="new_sku.sub_condition"
                                                :options="SubCondition.options()"
                                            ></form-select>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <input v-model="new_sku.leadtime" type="number" class="form-control" required>
                                                <span class="input-group-text">日</span>
                                            </div>
                                        </td>
                                        <td>
                                            <form-select
                                                v-model="new_sku.payment_type_exclusion"
                                                :options="PaymentTypeExclusion.options()"
                                            ></form-select>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <input v-model="new_sku.price" type="number" class="form-control" required>
                                                <span class="input-group-text">円</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <textarea v-model="sku.condition_note" class="form-control"></textarea>
                                        </td>
                                        <td colspan="1">
                                            <button @click="updateSku" type="submit" class="btn btn-lg btn-primary btn-full">SKUを更新</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                        <button class="btn btn-sm btn-outline-danger" @click="removeSku"><i class="bi bi-trash me-1"></i>SKUを削除</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-8">
                <div class="bg-light p-3">
                    <inline-loader v-if="asin_offers_loading" message="競合の出品情報を取得中"></inline-loader>
                    <table class="table" v-else>
                        <thead>
                            <tr>
                                <th>Amazon出品価格</th>
                                <th>コンディション</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="asin_offer,index of asin_offers" :key="index">
                                <td>{{ $helper.number(asin_offer.price) }}</td>
                                <td>{{ SubCondition.display(asin_offer.sub_condition) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-16 bg-light">
                <div class="p-3">
                    <inline-loader v-if="keyword === null || open_auctions_loading" message="現在開催中のオークションを取得中"></inline-loader>
                    <template v-else>
                        <form @submit.prevent="search_auction" class="row align-items-center">
                            <div class="col-5 text-bold">ヤフオクを検索</div>
                            <div class="col-12">
                                <input type="text" class="form-control" v-model="keyword">
                            </div>
                            <div class="col">
                                <button type="submit" class="btn btn-secondary">検索</button>
                            </div>
                        </form>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">オークション名</th>
                                    <th>現在価格</th>
                                    <th>即決価格</th>
                                    <th>残り時間</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="open_auction,index of open_auctions" :key="index">
                                    <td>
                                        <img :src="open_auction.image" style="width:50px;">
                                    </td>
                                    <td>
                                        <a target="_blank" :href="`${open_auction.url}?setChromeAsin=${sku.asin}`">{{ open_auction.title }}</a>
                                    </td>
                                    <td>{{ $helper.number(open_auction.current) }} 円</td>
                                    <td>{{ $helper.number(open_auction.buynow) }} 円</td>
                                    <td>{{ open_auction.period }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Sku from '@/shared/models/entities/sku'
import InlineLoader from '@/shared/components/tool/InlineLoader';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import SyncStatus from '@/shared/models/enums/sync-status';
import SubCondition from '@/shared/models/enums/sub-condition';
import PaymentTypeExclusion from '@/shared/models/enums/payment-type-exclusion';

export default {
    name: 'PartsInventoryItem',
    components: {
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        modelValue: {
            type: Sku
        },
        on_page_active: {
            type: Boolean,
            default: true,
        },
        load: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            sku: new Sku(),
            new_sku: new Sku(),
            active_auctions: [],
            active_auctions_loading: 0,
            ended_auctions: [],
            ended_auctions_loading: true,
            keyword: null,
            open_auctions: [],
            open_auctions_loading: true,
            asin_offers: [],
            asin_offers_loading: true,
            asin_fee: null,
            asin_fee_loading: true,

            editor_show: false,
            calculator: {
                base: 'profit_ratio', // or 'profit_price'
                listing_price: 0,
                estimated_cost: 0,
                referral_fee: 0,
                estimated_shipping: 0,
                other_fee: 0,
                profit_price: 0,
                profit_ratio: 0,
            },

            SyncStatus,
            SubCondition,
            PaymentTypeExclusion,
        }
    },
    mounted() {
        this.setValue();
        if (this.load) {
            this.init();
        }
    },
    emits: [
        'update:modelValue'
    ],
    watch: {
        modelValue: {
            handler: function () {
                this.setValue();
            },
            deep: true,
        },
    },
    methods: {
        setValue() {
            this.sku = this.modelValue;
            this.new_sku = new Sku(JSON.parse(JSON.stringify(this.sku)));
        },
        init() {
            if (this.on_page_active) {
                this.fetchActiveAuctions();
            }
            this.getAsinOffers();
            this.getSearchQuery(() => {
                this.getOpenAuctions();
            });
        },
        fetchActiveAuctions() {
            this.active_auctions = [];
            for (let auction of this.sku.active_auctions) {
                this.active_auctions_loading++;
                this.$http.get('/yahoo-auctions/auction', {params: {url: auction.auction_url}})
                .then(response => {
                    this.active_auctions.push({
                        auction_id: auction.auction_id,
                        url: auction.auction_url,
                        image: response.data.image,
                        title: response.data.title,
                        start: response.data.start,
                        end: response.data.end,
                        period: response.data.period,
                        current: response.data.current,
                        buynow: response.data.buynow,
                    });
                })
                .catch(() => {
                    this.active_auctions.push({
                        auction_id: auction.auction_id,
                        image: null,
                        title: null,
                        start: null,
                        end: null,
                        period: null,
                        current: null,
                        buynow: null,
                    });
                })
                .finally(() => {
                    this.active_auctions_loading--;
                });
            }
        },
        getEndedAuctions() {

        },
        getSearchQuery(next) {
            this.keyword = null;

            this.$http.get(`/search-queries/${this.sku.asin}`)
            .then(response => {
                this.keyword = response.data.keyword;
                if (next) {
                    next();
                }
            })
        },
        getOpenAuctions() {
            this.open_auctions = [];
            this.open_auctions_loading = true;

            this.$http.get('/yahoo-auctions', {params: {keyword: this.keyword}})
            .then(response => {
                for (let row of response.data) {
                    this.open_auctions.push(row);
                }
            })
            .finally(() => {
                this.open_auctions_loading = false;
            });
        },
        getAsinOffers() {
            this.asin_offers = [];
            this.asin_offers_loading = true;

            this.$http.get(`/asins/${this.sku.asin}/offers`)
            .then(response => {
                for (let row of response.data) {
                    this.asin_offers.push(row);
                }
            })
            .finally(() => {
                this.asin_offers_loading = false;
            });
        },
        search_auction() {
            this.getOpenAuctions();

            this.$http.put(`/search-queries/${this.sku.asin}`, {
                keyword: this.keyword
            });
        },
        openEditor() {
            if (this.editor_show) {
                this.editor_show = false;
            } else {
                this.editor_show = true;
                if (this.asin_fee === null) {
                    this.asin_fee_loading = true;

                    this.$http.get(`/asins/${this.sku.asin}/fee`)
                    .then(response => {
                        this.asin_fee = response.data;
                        //出品時の落札予想価格、想定送料と、現時点の手数料を元に、
                        //利益率・利益額を計算
                        this.calculator.listing_price = this.sku.price;
                        this.calculator.estimated_cost = this.sku.estimated_cost;
                        this.calculator.referral_fee = Math.round( this.calculator.listing_price * ((this.asin_fee.referral_fee ?? 0) / 1000) );
                        this.calculator.estimated_shipping = this.sku.estimated_shipping;
                        this.calculator.other_fee = (this.asin_fee.per_item_fee ?? 0) + (this.asin_fee.variable_closing_fee ?? 0)
                        this.calculator.profit_price = this.calculator.listing_price - this.calculator.estimated_cost - this.calculator.referral_fee - this.calculator.estimated_shipping - this.calculator.other_fee;
                        this.calculator.profit_ratio = Math.round(this.calculator.profit_price / this.calculator.listing_price * 1000) / 10;
                    })
                    .finally(() => {
                        this.asin_fee_loading = false;
                    });
                }
            }
        },

        updateSku() {
            this.startScreenLoading();
            this.$http.put('/skus/' + this.sku.sku_id, this.new_sku)
            .then((response) => {
                this.$emit('update:modelValue', new Sku(response.data));
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        release() {
            this.startScreenLoading();
            this.$http.post(`/skus/${this.sku.sku_id}/release`)
            .then((response) => {
                this.$emit('update:modelValue', new Sku(response.data));
                this.showMessage('保留解除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        retry() {
            this.startScreenLoading();
            this.$http.post(`/skus/${this.sku.sku_id}/retry`)
            .then((response) => {
                this.$emit('update:modelValue', new Sku(response.data));
                this.showMessage('再試行待ちにしました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeSku() {
            this.startScreenLoading();
            this.$http.delete('/skus/' + this.sku.sku_id)
            .then((response) => {
                this.$emit('update:modelValue', new Sku(response.data));
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeAuction(auction) {
            this.startScreenLoading();
            this.$http.delete(`/skus/${this.sku.sku_id}/auctions/${auction.auction_id}`)
            .then(() => {
                this.active_auctions = this.active_auctions.filter((row) => {
                    return row.auction_id !== auction.auction_id;
                });
                this.sku.active_auctions = this.sku.active_auctions.filter((row) => {
                    return row.auction_id !== auction.auction_id;
                });
                this.$emit('update:modelValue', this.sku);
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        calculate(base) {
            if (base) {
                this.calculator.base = base;
            }

            this.calculator.other_fee = (this.asin_fee.per_item_fee ?? 0) + (this.asin_fee.variable_closing_fee ?? 0)
            let referral_fee_rate = this.asin_fee.referral_fee / 1000;
            let cost = this.calculator.estimated_cost + this.calculator.estimated_shipping + this.calculator.other_fee;

            if (this.calculator.base == "profit_price") {
                this.calculator.listing_price = Math.round((cost + this.calculator.profit_price) / (1 - referral_fee_rate));
                this.calculator.referral_fee = Math.round(this.calculator.listing_price * referral_fee_rate);
                this.calculator.profit_ratio = Math.round((this.calculator.profit_price / this.calculator.listing_price) * 1000) / 10;
            } else {
                this.calculator.listing_price = Math.round(cost / (1 - referral_fee_rate - (this.calculator.profit_ratio / 100)));
                this.calculator.referral_fee = Math.round(this.calculator.listing_price * referral_fee_rate);
                this.calculator.profit_price = this.calculator.listing_price - this.calculator.referral_fee - cost;
            }

            this.new_sku.price = this.calculator.listing_price;
            for (let sku of this.skus) {
                sku.price = this.calculator.listing_price;
            }
        },
    }
}
</script>

<style scoped>

</style>
