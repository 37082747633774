import { isNud } from '@/shared/utilities/typing'

/**
 * Auction エンティティ
 */
class Auction {
    auction_id;
    auction_url;
    auction_title;
    auction_end;
    auction_end_price;
    is_open;
    register_datetime;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.auction_id = properties.auction_id;
        this.auction_url = properties.auction_url;
        this.auction_title = properties.auction_title;
        this.auction_end = properties.auction_end;
        this.auction_end_price = properties.auction_end_price;
        this.is_open = properties.is_open;
        this.register_datetime = properties.register_datetime;
    }
}

export default Auction;
