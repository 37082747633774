import Enum from './enum'

class SubCondition extends Enum {
    static NEW = 11;
    static LIKE_NEW = 21;
    static VERY_GOOD = 22;
    static GOOD = 23;
    static ACCEPTABLE = 24;

    static values() {
        return {
            [this.NEW]: '新品',
            [this.LIKE_NEW]: 'ほぼ新品',
            [this.VERY_GOOD]: 'とても良い',
            [this.GOOD]: '良い',
            [this.ACCEPTABLE]: '可',
        }
    }

    static display(key) {
        switch (key) {
            case 'new':
                return '新品';
            case 'like_new':
                return 'ほぼ新品';
            case 'very_good':
                return 'とても良い';
            case 'good':
                return '良い';
            case 'acceptable':
                return '可';
            default:
                return null;
        }
    }
}

export default SubCondition;
