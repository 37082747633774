import { isNud } from '@/shared/utilities/typing'
import User from './user';
import AsinCache from './asin-cache';
import Auction from './auction';
import SyncStatus from '@/shared/models/enums/sync-status';
import SubCondition from '@/shared/models/enums/sub-condition';
import PaymentTypeExclusion from '@/shared/models/enums/payment-type-exclusion';

/**
 * SKU エンティティ
 */
class Sku {
    sku_id;
    sku_code;
    asin;
    title;
    item_condition;
    sub_condition;
    condition_note;
    leadtime;
    payment_type_exclusion;
    price;
    quantity;
    asin_data_json;
    estimated_cost;
    estimated_shipping;
    is_draft;
    sync_status;
    register_user;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.sku_id = properties.sku_id;
        this.sku_code = properties.sku_code;
        this.asin = properties.asin;
        this.title = properties.title;
        this.item_condition = properties.item_condition;
        this.sub_condition = properties.sub_condition;
        this.condition_note = properties.condition_note;
        this.leadtime = properties.leadtime;
        this.payment_type_exclusion = properties.payment_type_exclusion;
        this.price = properties.price;
        this.original_price = properties.price; //変更前
        this.quantity = properties.quantity;
        this.asin_data_json = properties.asin_data_json;
        this.estimated_cost = properties.estimated_cost;
        this.estimated_shipping = properties.estimated_shipping;
        this.is_draft = properties.is_draft;
        this.sync_status = properties.sync_status;

        this.register_user = new User(properties.register_user);
        this.asin_cache = new AsinCache(properties.asin_cache);

        this.active_auctions = [];
        if (properties.active_auctions) {
            for (let auction of properties.active_auctions) {
                this.active_auctions.push(new Auction(auction));
            }
        }
    }

    get sync_status_display() {
        return SyncStatus.get(this.sync_status);
    }

    get sub_condition_display() {
        return SubCondition.get(this.sub_condition);
    }

    get payment_type_exclusion_display() {
        return PaymentTypeExclusion.get(this.payment_type_exclusion);
    }

    get uneditable() {
        return (
            this.sync_status === SyncStatus.DELETE_WAITING
            || this.sync_status === SyncStatus.DELETING
            || this.sync_status === SyncStatus.DELETE_ERROR
        )
    }
}

export default Sku;
