import Enum from './enum'

class PaymentTypeExclusion extends Enum {
    static EXCLUDE_NONE = 0;
    static EXCLUDE_COD = 1;
    static EXCLUDE_CVS = 2;
    static EXCLUDE_BOTH = 3;

    static values() {
        return {
            [this.EXCLUDE_NONE]: '除外なし',
            [this.EXCLUDE_COD]: '代引除外',
            [this.EXCLUDE_CVS]: 'コンビニ除外',
            [this.EXCLUDE_BOTH]: '両方除外',
        }
    }
}

export default PaymentTypeExclusion;
