<template>
    <section class="section">
        <form @submit.prevent="search" class="row">
            <div class="col-8">
                <input v-model="condition.keyword" type="text" class="form-control" placeholder="ASIN・SKU・商品名">
            </div>
            <div class="col-4">
                <form-select
                    v-model="condition.sync_status_group"
                    :options="options_sync_status_group"
                    empty_option = "--連携状態--"
                />
            </div>
            <div class="col-4">
                <form-select
                    v-model="condition.is_draft"
                    :options="options_is_draft"
                    empty_option = "-- 保留状態 --"
                />
            </div>
            <!--
            <div class="col-4">
                <select class="form-control">
                    <option value="">--カテゴリ--</option>
                    <option value=""></option>
                    <option value=""></option>
                    <option value=""></option>
                    <option value=""></option>
                </select>
            </div>
            -->
            <div class="col-4">
                <button type="submit" class="btn btn-primary btn-full">検索</button>
            </div>
            <div class="col-4">
                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                <button type="button" @click="show_checkbox = !show_checkbox" class="btn btn-light btn-full" ><i class="bi bi-caret-down-fill"></i> 一括処理</button>
                </template>
            </div>
        </form>
        <transition name="fade">
            <div class="mt-3 pt-3 border-top" v-if="show_checkbox">
                <div class="row">
                    <div class="col-8">
                        <button @click="checkAll" type="button" class="btn btn-secondary">
                            <i class="bi bi-check-square" v-if="checked_all"></i>
                            <i class="bi bi-square" v-else></i>
                            全てチェック
                        </button>
                    </div>
                    <div class="col-8">
                        <button @click="bulkRelease" type="button" class="btn btn-warning btn-full" :disabled="checked_sku_ids.length === 0">チェックした {{ checked_sku_ids.length }} 件を保留解除</button>
                    </div>
                    <div class="col-8">
                        <button @click="searchRelease" type="button" class="btn btn-warning btn-full" :disabled="paginator_meta.total === 0">表示中の {{ paginator_meta.total }} 件を全て保留解除</button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-8 offset-8">
                        <button @click="bulkDelete" type="button" class="btn btn-danger btn-full" :disabled="checked_sku_ids.length === 0">チェックした {{ checked_sku_ids.length }} 件を削除</button>
                    </div>
                    <div class="col-8">
                        <button @click="searchDelete" type="button" class="btn btn-danger btn-full" :disabled="paginator_meta.total === 0">表示中の {{ paginator_meta.total }} 件を全て削除</button>
                    </div>
                </div>
            </div>
        </transition>
    </section>

    <section class="section" v-for="sku,index of skus" :key="sku.sku_id" :class="{'deleting': sku.uneditable}">
        <transition name="fade">
            <div class="mb-1" v-if="show_checkbox">
                <label><input type="checkbox" v-model="checked_sku_ids" :value="sku.sku_id"> 選択</label>
            </div>
        </transition>
        <parts-inventory-item v-model="skus[index]" :on_page_active="(condition.is_watching === 1)"></parts-inventory-item>
    </section>

    <paginator :meta="paginator_meta" v-if="paginator_meta"></paginator>
</template>


<script>
/**
 * TODO
 *
 * カテゴリで検索
 * 個別削除後の表示
 * レイジーロードの実装
 */
import Sku from '@/shared/models/entities/sku'
import Paginator from '@/shared/components/tool/Paginator'
import PartsInventoryItem from './parts/PartsInventoryItem'
import FormSelect from '@/shared/components/form/FormSelect.vue';

export default {
    name: 'PageInventory',
    components: {
        Paginator,
        PartsInventoryItem,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            condition: {
                is_watching: 1,
                sync_status_group: null,
                is_draft: null,
                register_user_id: null,
                keyword: null,
            },
            skus: [],
            paginator_meta: null,
            show_checkbox: false,
            checked_all: false,
            checked_sku_ids: [],
            options_sync_status_group: [
                {'key': 100, 'label': '連携完了'},
                {'key': 200, 'label': '連携中'},
                {'key': 300, 'label': '連携エラー'},
            ],
            options_is_draft: [
                {'key': 0, 'label': '通常'},
                {'key': 1, 'label': '保留中'},
            ]
        }
    },
    mounted() {
        this.search();
    },
    /*
    // これだと /inventory <-> /inventory/closed を検知してくれなかった
    beforeRouteUpdate(to, from, next) {
        if (to !== from) {
            this.search();
        }
        next();
    },
    */
    watch: {
        $route() {
            this.search();
        }
    },
    methods: {
        search() {
            this.condition.register_user_id = this.$route.params.user;
            this.condition.is_watching = 'PageInventoryClosed' === this.$route.name ? 0 : 1;

            this.startScreenLoading();
            this.$http.get('/skus', {params: this.condition})
            .then(response => {
                this.skus = [];
                for (let row of response.data.data) {
                    this.skus.push(new Sku(row));
                }
                this.paginator_meta = response.data.meta;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        bulkDelete() {
            this.startScreenLoading();
            this.$http.post('/skus/bulk-delete', {sku_ids: this.checked_sku_ids})
            .then(() => {
                this.showMessage('削除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        searchDelete() {
            this.startScreenLoading();
            this.$http.post('/skus/search-delete', {
                condition: this.condition,
                count: this.paginator_meta.total
            })
            .then(() => {
                this.showMessage('削除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        bulkRelease() {
            this.startScreenLoading();
            this.$http.post('/skus/bulk-release', {sku_ids: this.checked_sku_ids})
            .then(() => {
                this.showMessage('保留解除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        searchRelease() {
            this.startScreenLoading();
            this.$http.post('/skus/search-release', {
                condition: this.condition,
                count: this.paginator_meta.total
            })
            .then(() => {
                this.showMessage('保留解除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        checkAll() {
            this.checked_all = !this.checked_all;

            this.checked_sku_ids = [];
            if (this.checked_all) {
                this.checked_sku_ids = this.skus.map(sku => sku.sku_id);
            }
        },
    }
}
</script>

<style scoped>
.deleting {background: #DDDDDD;}
</style>
