import Enum from './enum'

class SyncStatus extends Enum {
    // 0:完了 11:登録待ち 12:登録中 19:登録エラー 21:更新待ち 22:更新中 29:更新エラー 31:削除待ち 32:削除中 39:削除エラー
    static DONE = 0;
    static REGISTER_WAITING = 11;
    static REGISTERING = 12;
    static REGISTER_ERROR = 19;
    static UPDATE_WAITING = 21;
    static UPDATING = 22;
    static UPDATE_ERROR = 29;
    static DELETE_WAITING = 31;
    static DELETING = 32;
    static DELETE_ERROR = 39;

    static values() {
        return {
            [this.DONE]: 'Amazon同期完了',
            [this.REGISTER_WAITING]: 'Amazon登録待ち',
            [this.REGISTERING]: 'Amazon登録中',
            [this.REGISTER_ERROR]: 'Amazon登録エラー',
            [this.UPDATE_WAITING]: 'Amazon更新待ち',
            [this.UPDATING]: 'Amazon更新中',
            [this.UPDATE_ERROR]: 'Amazon更新エラー',
            [this.DELETE_WAITING]: 'Amazon削除待ち',
            [this.DELETING]: 'Amazon削除中',
            [this.DELETE_ERROR]: 'Amazon削除エラー',
        }
    }

    static isError(status) {
        return (
            status === this.REGISTER_ERROR
            || status === this.UPDATE_ERROR
            || status === this.DELETE_ERROR
        );
    }
}

export default SyncStatus;
