import { isNud } from '@/shared/utilities/typing'

/**
 * AsinCache エンティティ
 */
class AsinCache {
    asin_cache_id;
    asin;
    name;
    model;
    mpn;
    brand;
    label;
    manufacturer;
    publisher;
    studio;
    categories;
    img;
    width;
    height;
    length;
    weight;
    weight_units;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.asin_cache_id = properties.asin_cache_id;
        this.asin = properties.asin;
        this.name = properties.name;
        this.model = properties.model;
        this.mpn = properties.mpn;
        this.brand = properties.brand;
        this.label = properties.label;
        this.manufacturer = properties.manufacturer;
        this.publisher = properties.publisher;
        this.studio = properties.studio;
        this.categories = properties.categories;
        this.img = properties.img;
        this.width = properties.width;
        this.height = properties.height;
        this.length = properties.length;
        this.weight = properties.weight;
        this.weight_units = properties.weight_units;
    }
}

export default AsinCache;
